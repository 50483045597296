// ImageUploadContext.js
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { toast } from 'react-toastify';
import { useOrderActions } from './OrderContext';

const ImageUploadContext = createContext();
const ImageUploadActionsContext = createContext();

export const ImageUploadProvider = ({ children }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const { getOrderss } = useOrderActions();

  const sendImage = async (imageFile, orderId) => {
    try {
      const formData = new FormData();
      imageFile.forEach((file) => {
        formData.append('file', file);
      });
      const response = await apiService.post(`/s3/upload?orderId=${orderId}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUploadedImages((prevImages) => [...prevImages, response.data]);
      toast.success('Image Uploaded!');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image!');
    }
  };

  const sendSignifyFile = async (imageFile, orderId) => {
    try {
      const formData = new FormData();
      imageFile.forEach((file) => {
        formData.append('file', file);
      });
      const response = await apiService.post(`/signify/upload/${orderId}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUploadedImages((prevImages) => [...prevImages, response.data]);
      toast.success('Image Uploaded!');
      getOrderss();
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image!');
    }
  };
  const deleteImage = async (deletedData) => {
    try {
      const response = await apiService.post('/s3/delete', deletedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      // toast.success('Image Deleted!');
    } catch (error) {
      console.error(error);
      // toast.success('Error Deleting Image!');
    }
  };

  const sendImagesCallback = useCallback(sendImage, []);
  const sendSignifyFileCallback = useCallback(sendSignifyFile, [getOrderss]);

  const deleteImageCallback = useCallback(deleteImage, []);

  const imageUploadActionsValue = useMemo(
    () => ({
      sendImage: sendImagesCallback,
      sendSignifyFile: sendSignifyFileCallback,
      deleteImage: deleteImageCallback,
    }),
    [sendImagesCallback, deleteImageCallback, sendSignifyFileCallback]
  );

  const uploadedImagesValue = useMemo(() => uploadedImages, [uploadedImages]);

  return (
    <ImageUploadContext.Provider value={uploadedImagesValue}>
      <ImageUploadActionsContext.Provider value={imageUploadActionsValue}>
        {children}
      </ImageUploadActionsContext.Provider>
    </ImageUploadContext.Provider>
  );
};

export const useUploadedImages = () => useContext(ImageUploadContext);
export const useImageUploadActions = () => useContext(ImageUploadActionsContext);

ImageUploadProvider.propTypes = {
  children: PropTypes.element,
};
