import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomerListPage from 'src/pages/dashboard/customer/list';
import OrderListPage from 'src/pages/dashboard/order/list';
import CountryListPage from 'src/pages/dashboard/country/list';
import BranchListPage from 'src/pages/dashboard/branch/list';
import KanbanListPage from 'src/pages/dashboard/kanban/list';
import AirwayBillListPage from 'src/pages/dashboard/airWaybill/list';
import BoxListPage from 'src/pages/dashboard/box/lits';
import PalleteListPage from 'src/pages/dashboard/pallete/list';
import ShelfListPage from 'src/pages/dashboard/shelf/list';
import WarehouseLocationListPage from 'src/pages/dashboard/warehouseLocation/list';
import AddressListPage from 'src/pages/dashboard/address/list';
import TimeSlotListPage from 'src/pages/dashboard/timeSlots/list';
import StatusUpdateList from 'src/pages/dashboard/statusUpdate/list';
import CheckOrderList from 'src/pages/dashboard/checkOrder/list';
import ContentManagementList from 'src/pages/dashboard/contentManagement/list';
import RSListPage from 'src/pages/dashboard/rs/list';
import PriceListPage from 'src/pages/dashboard/prices/list';
import PaymentListPage from 'src/pages/dashboard/payments/list';
import LiveOrdersListPage from 'src/pages/dashboard/liveOrders/list';
import CustomOrdersListPage from 'src/pages/dashboard/customOrders/list';
import InventarizationListPage from 'src/pages/dashboard/inventarization/list';

import { jwtDecode } from 'jwt-decode';
import {
  OrderProvider,
  BranchProvider,
  CountryProvider,
  CustomerProvider,
  BoxProvider,
  PalletProvider,
  ShelfProvider,
  ImageUploadProvider,
  ContentProvider,
  AirwayBillProvider,
  AddressProvider,
  PriceProvider,
  PaymentProvider,
} from '../../context/providers';

const auth = JSON.parse(localStorage.getItem('token'));

// Helper function to wrap providers
function wrapProviders(component) {
  return (
    <BoxProvider>
      <PaymentProvider>
        <PalletProvider>
          <PriceProvider>
            <ShelfProvider>
              <OrderProvider>
                <ImageUploadProvider>
                  <BranchProvider>
                    <CustomerProvider>
                      <ContentProvider>
                        <AirwayBillProvider>
                          <AddressProvider>
                            <CountryProvider>{component}</CountryProvider>
                          </AddressProvider>
                        </AirwayBillProvider>
                      </ContentProvider>
                    </CustomerProvider>
                  </BranchProvider>
                </ImageUploadProvider>
              </OrderProvider>
            </ShelfProvider>
          </PriceProvider>
        </PalletProvider>
      </PaymentProvider>
    </BoxProvider>
  );
}

let routChildren = [];

if (auth) {
  const decoded = jwtDecode(auth);
  if (decoded.location === 'USA') {
    routChildren = [
      { element: <Navigate to="customer" />, index: true },
      {
        path: 'customer',
        children: [
          { element: <CustomerListPage />, index: true },
          { path: ':customer_id', element: <CustomerListPage /> },
        ],
      },
      {
        path: 'orders',
        children: [
          { element: <OrderListPage />, index: true },
          { path: ':order_id', element: <OrderListPage /> },
        ],
      },
      {
        path: 'kanban',
        children: [{ element: <KanbanListPage />, index: true }],
      },
      {
        path: 'OrderStatus',
        children: [{ element: <StatusUpdateList />, index: true }],
      },
      {
        path: 'box',
        children: [{ element: <BoxListPage />, index: true }],
      },
      {
        path: 'pallet',
        children: [{ element: <PalleteListPage />, index: true }],
      },
      {
        path: 'airwayBill',
        children: [{ element: <AirwayBillListPage />, index: true }],
      },
    ];
  }
  if (decoded.location === 'Georgia') {
    routChildren = [
      { element: <Navigate to="customer" />, index: true },
      {
        path: 'customer',
        children: [
          { element: <CustomerListPage />, index: true },
          { path: ':customer_id', element: <CustomerListPage /> },
        ],
      },
      {
        path: 'customer-address-details',
        children: [{ element: <CustomerListPage />, index: true }],
      },

      {
        path: 'payments',
        children: [
          { element: <PaymentListPage />, index: true },
          { path: ':payment_id', element: <PaymentListPage /> },
        ],
      },
      {
        path: 'orders',
        children: [
          { element: <OrderListPage />, index: true },
          { path: ':order_id', element: <OrderListPage /> },
        ],
      },
      {
        path: 'branch',
        children: [{ element: <BranchListPage />, index: true }],
      },
      {
        path: 'country',
        children: [{ element: <CountryListPage />, index: true }],
      },
      {
        path: 'kanban',
        children: [{ element: <KanbanListPage />, index: true }],
      },
      {
        path: 'box',
        children: [{ element: <BoxListPage />, index: true }],
      },
      {
        path: 'pallet',
        children: [{ element: <PalleteListPage />, index: true }],
      },
      {
        path: 'shelf',
        children: [{ element: <ShelfListPage />, index: true }],
      },
      {
        path: 'WarehouseLocation',
        children: [{ element: <WarehouseLocationListPage />, index: true }],
      },
      {
        path: 'TimeSlot',
        children: [{ element: <TimeSlotListPage />, index: true }],
      },
      {
        path: 'OrderStatus',
        children: [{ element: <StatusUpdateList />, index: true }],
      },
      {
        path: 'OrderCheck',
        children: [{ element: <CheckOrderList />, index: true }],
      },

      {
        path: 'ContentManagement',
        children: [{ element: <ContentManagementList />, index: true }],
      },
      {
        path: 'airwayBill',
        children: [{ element: <AirwayBillListPage />, index: true }],
      },
      {
        path: 'address',
        children: [{ element: <AddressListPage />, index: true }],
      },
      {
        path: 'rs',
        children: [{ element: <RSListPage />, index: true }],
      },
      {
        path: 'price',
        children: [{ element: <PriceListPage />, index: true }],
      },
      {
        path: 'liveOrders',
        children: [{ element: <LiveOrdersListPage />, index: true }],
      },
      {
        path: 'customOrders',
        children: [{ element: <CustomOrdersListPage />, index: true }],
      },
      {
        path: 'inventarization',
        children: [{ element: <InventarizationListPage />, index: true }],
      },
    ];
  }
  if (decoded.location === 'China') {
    routChildren = [
      { element: <Navigate to="customer" />, index: true },
      {
        path: 'customer',
        children: [
          { element: <CustomerListPage />, index: true },
          { path: ':customer_id', element: <CustomerListPage /> },
        ],
      },
      {
        path: 'orders',
        children: [
          { element: <OrderListPage />, index: true },
          { path: ':order_id', element: <OrderListPage /> },
        ],
      },
      {
        path: 'kanban',
        children: [{ element: <KanbanListPage />, index: true }],
      },
      {
        path: 'OrderStatus',
        children: [{ element: <StatusUpdateList />, index: true }],
      },
      {
        path: 'box',
        children: [{ element: <BoxListPage />, index: true }],
      },
      {
        path: 'pallet',
        children: [{ element: <PalleteListPage />, index: true }],
      },
    ];
  }
}
export const dashboardRoutes = auth
  ? [
      {
        path: 'dashboard',
        element: (
          <DashboardLayout>
            {wrapProviders(
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            )}
          </DashboardLayout>
        ),
        children: routChildren,
      },
    ]
  : [];
