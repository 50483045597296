import { Button, Drawer, IconButton, Typography, MenuItem, Select, Box } from '@mui/material';
import SelectFieldForOrder from 'src/custom_components/Froms/selectForOrder';
import { jwtDecode } from 'jwt-decode';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useMemo, useEffect } from 'react';
import { _orders } from 'src/_mock';
import { useOrderActions, useOrderData } from 'src/context/providers/OrderContext';
import CustomizedSwitches from 'src/custom_components/Froms/InputSwitcher';
import InputFields from 'src/custom_components/Froms/InputFields';
import DatePickerValue from 'src/custom_components/Froms/datePicker';
import OrderDetailsHistory from './order-details-history';
import OrderDetailsItems from './order-details-item';
import OrderDetailsInfo from './order-details-info';

import {
  additionalInformation,
  orderDetails,
  status,
  trackingInformation,
  status_colors,
  time_slot_select,
  declaration,
} from './view/data';

const OrderDrawerComponent = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow, filters }) => {
  const { updateOrder, getOrderss } = useOrderActions();
  const [originalData, setOriginalData] = useState(aRow);
  const [formData, setFormData] = useState({
    id: aRow.id || null,
    tracking_number: aRow.tracking_number || null,
    tracking_number_2: aRow.tracking_number_2 || null,
    room_number: aRow?.customer?.room_number || null,
    content: aRow.content || null,
    additional_identifier: aRow.additional_identifier || null,
    price: aRow.price || null,
    status: aRow.status || null,
    additional_details_id: aRow.additional_details_id || null,
    customer_id: aRow.customer_id || null,
    branch_id: aRow.branch_id || null,
    box_id: aRow.box_id || null,
    pallet_id: aRow.pallet_id || null,
    shelf_id: aRow.shelf_id || null,
    timeSlot: aRow.timeSlot || null,
    order_date: aRow.order_date || '',
    wrapping: aRow.wrapping || null,
    payed: aRow.payed || null,
    width: aRow.width || null,
    height: aRow.height || null,
    len: aRow.len || null,
    volume: aRow.volume || null,
    weight: aRow.weight || null,
    width_before: aRow.width_before || null,
    height_before: aRow.height_before || null,
    len_before: aRow.len_before || null,
    volume_before: aRow.volume_before || null,
    weight_before: aRow.weight_before || null,
    declaration_status: aRow.declaration_status || null,
    tracking_status: aRow.tracking_status || null,
    custom_status: aRow.custom_status || null,
    delivery_status: aRow.delivery_status || null,
    pay_status: aRow.pay_status,
    vatPaymentAuto: aRow.vatPaymentAuto,
    DeclarationPaymentAuto: aRow.DeclarationPaymentAuto,
    NeedsCustom: aRow.NeedsCustom,
    vat: aRow.vat,
    vat_payment_status: aRow.vat_payment_status,
    declaration_id_for_vat: aRow.declaration_id_for_vat || null,
    vat_amount: aRow.vat_amount || null,
    comment: '',
    price_gz: aRow.price_gz || null,
  });
  // eslint-disable-next-line
  const course = useOrderData().course;
  const [activeNavItem, setActiveNavItem] = useState('Customer Details');

  const [navActive, setNavActive] = useState('i');
  useMemo(() => {
    setOriginalData(aRow);
  }, [aRow]);

  const identifyChanges = () => {
    const changes = {};
    Object.entries(formData).forEach(([key, value]) => {
      // Compare current form data with original data
      if (originalData[key] !== value) {
        changes[key] = value;
      }
    });
    return changes;
  };

  const handleNavItemClick = (item) => {
    setActiveNavItem(item);
  };
  const getNavItemStyle = (item) => {
    if (item === activeNavItem) {
      return {
        width: '50%',
        padding: '0px',
        textAlign: 'center',
        borderBottom: '3px solid rgba(49, 134, 136, 0.4)',
      };
    }
    return {
      width: '50%',
      padding: '16px',
      textAlign: 'center',
    };
  };

  const handleNavKeyPress = (event, item) => {
    if (event.key === 'Enter') {
      handleNavItemClick(item);
    }
  };

  const DrawerConfig = {
    SubNavigation: false,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: formData?.tracking_number,
    SubNav: true,
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleOutsideClick = (event) => {
    // if (event.target === event.currentTarget) {
    handleDrawerToggle();
    Clear();
    setARow({});
    // }
  };

  const Clear = () => {
    setFormData({
      id: null,
      tracking_number: null,
      tracking_number_2: null,
      room_number: null,
      weight: null,
      content: null,
      additional_identifier: null,
      price: null,
      status: null,
      additional_details_id: null,
      customer_id: null,
      branch_id: null,
      box_id: null,
      pallet_id: null,
      shelf_id: null,
      order_date: '',
      timeSlot: null,
      wrapping: null,
      payed: null,
      width: null,
      height: null,
      len: null,
      volume: null,
      width_before: null,
      height_before: null,
      len_before: null,
      volume_before: null,
      weight_before: null,
      declaration_status: null,
      tracking_status: null,
      custom_status: null,
      delivery_status: null,
      pay_status: null,
      vatPaymentAuto: null,
      DeclarationPaymentAuto: null,
      NeedsCustom: null,
      vat: null,
      vat_payment_status: null,
      declaration_id_for_vat: null,
      vat_amount: null,
      comment: '',
    });
  };

  const id = 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2';
  const currentOrder = _orders.filter((order) => order.id === id)[0];
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const UpdateFields = () => {
    const changes = identifyChanges();
    // If there are no changes, log a message and exit the function
    if (Object.keys(changes).length === 0) {
      return;
    }
    // Proceed with the update using only the changed fields
    updateOrder(formData.id, changes)
      .then(() => {
        handleDrawerToggle();
        getOrderss(filters);
        Clear();
        setARow({});
      })
      .catch((error) => {
        console.error('Update failed:', error);
      });
  };

  useEffect(() => {
    const width = Number(formData.width);
    const height = Number(formData.height);
    const len = Number(formData.len);

    if (
      Number.isFinite(width) &&
      Number.isFinite(height) &&
      Number.isFinite(len) &&
      width > 0 &&
      height > 0 &&
      len > 0
    ) {
      const newVolume = (width * len * height) / 6000;
      setFormData((prevFormData) => ({
        ...prevFormData,
        volume: newVolume.toFixed(2),
      }));
    }
  }, [formData.width, formData.height, formData.len]);

  useEffect(() => {
    const width = Number(formData.width);
    const height = Number(formData.height);
    const len = Number(formData.len);

    if (
      Number.isFinite(width) &&
      Number.isFinite(height) &&
      Number.isFinite(len) &&
      width > 0 &&
      height > 0 &&
      len > 0
    ) {
      const newVolume = (width * len * height) / 6000;
      setFormData((prevFormData) => ({
        ...prevFormData,
        volume: newVolume.toFixed(2),
      }));
    }
  }, [formData.width, formData.height, formData.len]);

  useEffect(() => {
    const widthBefore = Number(formData.width_before);
    const heightBefore = Number(formData.height_before);
    const lenBefore = Number(formData.len_before);

    if (
      Number.isFinite(widthBefore) &&
      Number.isFinite(heightBefore) &&
      Number.isFinite(lenBefore) &&
      widthBefore > 0 &&
      heightBefore > 0 &&
      lenBefore > 0
    ) {
      const newVolumeBefore = (widthBefore * lenBefore * heightBefore) / 6000;
      setFormData((prevFormData) => ({
        ...prevFormData,
        volume_before: newVolumeBefore.toFixed(2),
      }));
    }
  }, [formData.width_before, formData.height_before, formData.len_before]);

  // useMemo(() => {
  //   if (formData.width && formData.len && formData.height) {
  //     const newVolu = (formData.width * formData.len * formData.height) / 6000;
  //     setFormData({
  //       ...formData,
  //       volume: newVolu,
  //     });
  //   }
  //   if (formData.width_before && formData.len_before && formData.height_before) {
  //     const newVolume_before =
  //       (formData.width_before * formData.len_before * formData.height_before) / 6000;
  //     setFormData({
  //       ...formData,
  //       volume_before: newVolume_before,
  //     });
  //   }
  // }, [formData]);
  const auth = JSON.parse(localStorage.getItem('token'));
  let decoded = '';
  if (auth) {
    decoded = jwtDecode(auth);
  }

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleOutsideClick();
        }
      }}
      // onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleOutsideClick}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            {Object.keys(formData).map((key) => {
              if (key === 'status') {
                return (
                  <Select
                    key={key}
                    name={key}
                    value={formData[key]}
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: status_colors[formData.status]
                        ? status_colors[formData.status][0]
                        : '',
                      color: status_colors[formData.status]
                        ? status_colors[formData.status][1]
                        : '',
                    }}
                    variant="outlined"
                    sx={{ width: '100%', height: '40px' }}
                  >
                    {status.map((i, index) => (
                      <MenuItem key={index} value={i}>
                        {i.replace(/_/g, ' ')}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }
              return null;
            })}
            <IconButton onClick={handleOutsideClick}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Subnavigation */}
        {DrawerConfig.SubNav && (
          <div>
            <div style={{ display: 'flex', paddingTop: '10px' }}>
              <div
                role="button"
                tabIndex={0}
                style={getNavItemStyle('Customer Details')}
                onClick={() => {
                  handleNavItemClick('Customer Details');
                  setNavActive('i');
                }}
                onKeyPress={(event) => handleNavKeyPress(event, 'Customer Details')}
              >
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', paddingTop: '15px' }}>
                  Order Management
                </Typography>
              </div>
              <div
                role="button"
                tabIndex={0}
                style={getNavItemStyle('Order History')}
                onClick={() => {
                  handleNavItemClick('Order History');
                  setNavActive('d');
                }}
                onKeyPress={(event) => handleNavKeyPress(event, 'Order History')}
              >
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', paddingTop: '15px' }}>
                  Order&apos;s Editional Information
                </Typography>
              </div>
            </div>
          </div>
        )}

        <div
          className="content-wrap"
          style={{
            width: '100%',
            minHeight: '87.5%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {/* Form */}
          {navActive === 'i' && (
            <form style={{ padding: '16px', marginTop: '10px' }}>
              {/* Tracking Information */}
              <InputFields
                userData={formData}
                heading="Tracking Information"
                inputFields={trackingInformation}
                setUserData={setFormData}
                hasCopy
              />

              {/* Tracking Information */}
              {/* Order details */}
              <InputFields
                userData={formData}
                heading="Order Details"
                inputFields={orderDetails}
                setUserData={setFormData}
              />
              {/* Order details */}
              <Box
                sx={{
                  width: '50%',
                  margin: '0px 20px',
                  borderTop: '2px solid #2AA76F',
                  borderBottom: '2px solid #2AA76F',
                }}
              >
                Price -{' '}
                {/* {formData?.weight
                  ? (
                      (+formData.weight ?? 0) * (course ?? 0) * 8 -
                      ((+formData.weight ?? 0) *
                        (course ?? 0) *
                        8 *
                        (aRow?.customer?.promotion ?? 0)) /
                        100
                    ).toFixed(2)
                  : ''} */}
                {formData?.weight
                  ? (
                      (+formData.weight ?? 0) * (course ?? 0) * 8 -
                      ((+formData.weight ?? 0) * (course ?? 0) * 8 * 20) / 100
                    ).toFixed(2)
                  : ''}
              </Box>

              {/* Wrapping */}
              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                  padding: '30px 0px 0px 20px',
                }}
              >
                <Typography gutterBottom className="subTitles">
                  Wrapping
                </Typography>
                <div
                  className="content-wrap"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <CustomizedSwitches
                    text="wrapping"
                    isChecked={formData.wrapping}
                    userData={formData}
                    setUserData={setFormData}
                  />
                </div>
              </div>
              {/* Wrapping */}

              {/* Box and pallets */}
              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  padding: '30px 0px 0px 20px',
                }}
              >
                <Typography gutterBottom className="subTitles">
                  Box Tracking - {aRow?.box?.tracking}
                </Typography>
                {/* <SelectFieldForOrder
                  key="box"
                  widthComp="95%"
                  selectName="box_id"
                  setter={handleInputChange}
                  data={boxes}
                  selectedValue={formData.box_id}
                  optionName="tracking"
                /> */}
                {/* <SelectFieldForOrder
                  key="pallete"
                  widthComp="95%"
                  selectName="pallet_id"
                  data={palletes}
                  setter={handleInputChange}
                  selectedValue={formData.pallet_id}
                  optionName="tracking"
                /> */}
                {/* {decoded?.location === 'Georgia' ? (
                  <SelectFieldForOrder
                    key="shelf"
                    widthComp="95%"
                    selectName="shelf_id"
                    data={shelfs}
                    setter={handleInputChange}
                    selectedValue={formData.shelf_id}
                    optionName="code"
                  />
                ) : (
                  ''
                )} */}
              </div>
              {/* box and pallets */}

              {/* Delivery Time */}

              {decoded?.location === 'Georgia' ? (
                <div
                  className="content-wrap"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    padding: '30px 0px 0px 20px',
                  }}
                >
                  <Typography gutterBottom className="subTitles">
                    Delivery time
                  </Typography>

                  <SelectFieldForOrder
                    key="timeSlot"
                    widthComp="95%"
                    selectName="timeSlot"
                    data={time_slot_select}
                    setter={handleInputChange}
                    selectedValue={formData.timeSlot}
                    optionName="name"
                  />

                  <DatePickerValue
                    setter={handleInputChange}
                    value={new Date(formData.order_date)}
                    selectName="order_date"
                  />
                </div>
              ) : (
                ''
              )}

              {/* Delivery Time */}

              {/* Order Steps */}
              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  padding: '30px 0px 0px 20px',
                }}
              >
                <Typography gutterBottom className="subTitles">
                  Order Steps
                </Typography>

                {decoded?.location === 'Georgia' ? (
                  <>
                    <SelectFieldForOrder
                      key="declaration_status"
                      widthComp="95%"
                      selectName="declaration_status"
                      data={[
                        { id: 'DECLERED', name: 'DECLERED' },
                        { id: 'NOT DECLERED', name: 'NOT DECLERED' },
                      ]}
                      setter={handleInputChange}
                      selectedValue={formData.declaration_status}
                      optionName="name"
                    />
                    <SelectFieldForOrder
                      key="tracking_status"
                      widthComp="95%"
                      selectName="tracking_status"
                      data={[
                        { id: 'TRACKING', name: 'TRACKING' },
                        { id: 'NOT TRACKING', name: 'NOT TRACKING' },
                      ]}
                      setter={handleInputChange}
                      selectedValue={formData.tracking_status}
                      optionName="name"
                    />

                    <SelectFieldForOrder
                      key="custom_status"
                      widthComp="95%"
                      selectName="custom_status"
                      data={[
                        { id: '0', name: '0' },
                        { id: '1', name: '1' },
                        { id: '2', name: '2' },
                        { id: '3', name: '3' },
                        { id: '4', name: '4' },
                        { id: '5', name: '5' },
                        { id: '6', name: '6' },
                        { id: '7', name: '7' },
                        { id: '8', name: '8' },
                        { id: '9', name: '9' },
                        { id: '10', name: '10' },
                      ]}
                      setter={handleInputChange}
                      selectedValue={formData.custom_status}
                      optionName="name"
                    />

                    <SelectFieldForOrder
                      key="delivery_status"
                      widthComp="95%"
                      selectName="delivery_status"
                      data={[
                        { id: 'DELIVERED', name: 'DELIVERED' },
                        { id: 'NOT DELIVERED', name: 'NOT DELIVERED' },
                      ]}
                      setter={handleInputChange}
                      selectedValue={formData.delivery_status}
                      optionName="name"
                    />

                    <SelectFieldForOrder
                      key="pay_status"
                      widthComp="95%"
                      selectName="pay_status"
                      data={[
                        { id: 'PAYED', name: 'PAYED' },
                        { id: 'NOT PAYED', name: 'NOT PAYED' },
                      ]}
                      setter={handleInputChange}
                      selectedValue={formData.pay_status}
                      optionName="name"
                    />

                    {/* Order declaration */}
                    <InputFields
                      userData={formData}
                      heading=""
                      inputFields={declaration}
                      setUserData={setFormData}
                      noPadding
                    />
                    {/* Order declaration */}
                  </>
                ) : (
                  ''
                )}
              </div>
              {/* Order Steps */}

              {/* declarations */}

              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                  padding: '30px 0px 0px 20px',
                }}
              >
                <Typography gutterBottom className="subTitles">
                  Declaration
                </Typography>
                <div
                  className="content-wrap"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '10px',
                  }}
                >
                  <CustomizedSwitches
                    text="vatPaymentAuto"
                    name="Yellow Pay by user"
                    isChecked={formData.vatPaymentAuto}
                    userData={formData}
                    setUserData={setFormData}
                  />

                  {aRow.DeclarationPaymentAuto === null ? (
                    'RS Fee Pay GZ NOT SELECTED YET'
                  ) : (
                    <CustomizedSwitches
                      text="DeclarationPaymentAuto"
                      name="RS Fee Pay GZ"
                      isChecked={formData.DeclarationPaymentAuto}
                      userData={formData}
                      setUserData={setFormData}
                    />
                  )}

                  <CustomizedSwitches
                    text="NeedsCustom"
                    isChecked={formData.NeedsCustom}
                    userData={formData}
                    setUserData={setFormData}
                  />
                  <CustomizedSwitches
                    text="vat"
                    name="Yellow status"
                    isChecked={formData.vat}
                    userData={formData}
                    setUserData={setFormData}
                  />
                  <CustomizedSwitches
                    text="vat_payment_status"
                    name="Yellow payment status"
                    isChecked={formData.vat_payment_status}
                    userData={formData}
                    setUserData={setFormData}
                  />
                </div>
              </div>
              {/* declarations */}
              {/* Additional Infomation */}

              <InputFields
                userData={formData}
                heading="Additional Infomation"
                inputFields={additionalInformation}
                setUserData={setFormData}
              />
              {/* Additional Infomation */}
              {/* Button */}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderTop: '1px solid rgba(220, 220, 220, 0.2)',
                  padding: '16px',
                  marginRight: '10px',
                }}
              >
                <Button variant="contained" onClick={UpdateFields}>
                  UPDATE
                </Button>
              </div>
            </form>
          )}
          {navActive === 'd' && (
            <div
              className="internal"
              style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '32px 0',
              }}
            >
              <div style={{ width: '96%' }}>
                <OrderDetailsInfo
                  aRow={aRow}
                  customer={currentOrder.customer}
                  delivery={currentOrder.delivery}
                  payment={currentOrder.payment}
                  shippingAddress={currentOrder.shippingAddress}
                  formData={formData}
                  handleDrawerToggle={handleDrawerToggle}
                />
              </div>

              {aRow?.orderHistory?.length > 0 ? (
                <div style={{ width: '96%' }}>
                  <OrderDetailsHistory history={aRow.orderHistory} />
                </div>
              ) : (
                ''
              )}

              <div style={{ width: '96%', margin: '16px 0' }}>
                <OrderDetailsItems
                  items={currentOrder.items}
                  aRow={aRow}
                  taxes={currentOrder.taxes}
                  shipping={currentOrder.shipping}
                  discount={currentOrder.discount}
                  subTotal={currentOrder.subTotal}
                  totalAmount={currentOrder.totalAmount}
                />
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

OrderDrawerComponent.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default OrderDrawerComponent;
